import { lazy, Suspense, useEffect, useMemo } from 'react';
import './i18n';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import RouteInfo from '@cfacorp/ctrl-platform-ui-core-utils/dist/types/RouteInfo';
import { globalRoutes } from '@cfacorp/ctrl-platform-ui-core-utils';
import RouteConstants from './routes/RouteConstants';

const AppRouter = lazy(() => import('./routes/AppRouter'));
const queryClient = new QueryClient();

export default function Root() {
  const routes: RouteInfo[] = useMemo(
    () => [
      {
        route: RouteConstants.HISTORY,
        displayName: 'Change History',
        keywords: [
          'change',
          'history',
          'past',
          'update',
          'publish',
          'cart',
          'saved',
        ],
      },
      {
        route: RouteConstants.ADDED_TO_CART,
        displayName: 'Added to Cart History',
        keywords: ['Added', 'Cart', 'History'],
      },
      {
        route: RouteConstants.CARES_SUPPORT_TIERS,
        displayName: 'Cares Support Tiers',
        keywords: ['cares', 'support', 'tier'],
      },
      {
        route: RouteConstants.HELP,
        displayName: 'Help',
        keywords: ['help', 'faq', 'support'],
      },
      {
        route: RouteConstants.REPORTS,
        displayName: 'Reports',
        keywords: ['reports'],
      },
    ],
    [],
  );

  useEffect(() => {
    globalRoutes.next({
      routes: [...globalRoutes.value.routes, ...routes],
    });
  }, [routes]);

  return (
    <QueryClientProvider client={queryClient}>
      <Suspense fallback={null}>
        <AppRouter />
      </Suspense>
    </QueryClientProvider>
  );
}
